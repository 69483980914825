import {
  Environment,
  IEnvironmentInfo,
} from '../sgwt-sgbot-ms.types';

export const BUS_ACCESS_TOKEN = 'sg-connect.access-token';

const environmentInfo: IEnvironmentInfo = {
  development: {
    'direct-line': '/api/directline/v3',
    'sg-connect-v1': 'https://api-s-uat.sgmarkets.com/services/insight/serviceboard/private/v2/me',
    'sg-connect-v2': 'https://sgconnect-hom.fr.world.socgen/sgconnect',
  },
  homologation: {
    'direct-line': 'https://sgbot-uat.fr.world.socgen/api/directline/v3',
    'sg-connect-v1': 'https://api-s-uat.sgmarkets.com/services/insight/serviceboard/private/v2/me',
    'sg-connect-v2': 'https://sgconnect-hom.fr.world.socgen/sgconnect',
  },
  production: {
    'direct-line': 'https://sgbot.fr.world.socgen/api/directline/v3',
    // 'direct-line': 'https://sgbot-prd2.fr.world.socgen/api/directline/v3',
    'sg-connect-v1': 'https://api-s.sgmarkets.com/services/insight/serviceboard/private/v2/me',
    'sg-connect-v2': 'https://sso.sgmarkets.com/sgconnect',
  },
};

export function checkStatus(response: Response): Response {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error: Error = new Error(response.statusText);
    throw error;
  }
}

export function getDirectLineEndpoint(env: Environment): string {
  return environmentInfo[env]['direct-line'];
}

export function getSgConnectEndpoint(env: Environment, mode: string): string {
  return environmentInfo[env][mode];
}

export function parseJson(response: Response): Promise<object> {
  return response.json();
}

export function isDarkTheme() {
  let darkTheme = false;
  const span = document.createElement('span');
  span.classList.add('text-primary');
  document.body.appendChild(span);
  if (window.getComputedStyle(span).color === 'rgb(255, 255, 255)') {
    darkTheme = true;
  }
  span.remove();
  return darkTheme;
}
