import {
  h,
  initializeWidgetConfiguration,
  SgWidget,
  WidgetConfigurationProvider,
  WithGlobalCss
} from '@sgwt-widget/core';
import { ComponentProps, emit, props } from 'skatejs';
import WebChat from './components/WebChat';
import { ISgwtSgbotMsProps } from './sgwt-sgbot-ms.types';

import * as css from './sgwt-sgbot-ms.scss';
import { SgwtWidgetName, startWidgetMonitoring } from '../common/monitoring';

export class SgwtSgbotMs extends SgWidget<ISgwtSgbotMsProps> {
  public static is = 'sgwt-sgbot-ms';
  public static props: ComponentProps<ISgwtSgbotMsProps> = {
    chatContext: props.string,
    conversationId: props.string,
    noConsole: props.boolean,
    random: props.string,
    sgConnectSupport: props.string
  };
  private _firstTime: boolean = true;

  constructor() {
    super();
    this.widgetConfiguration.log(`${SgwtSgbotMs.is} constructed`);
    startWidgetMonitoring(SgwtWidgetName.SGBOT_MS);
  }

  public render({
    chatContext,
    conversationId,
    noConsole,
    random,
    sgConnectSupport
  }: ISgwtSgbotMsProps): JSX.Element {
    return (
      <WidgetConfigurationProvider widgetConfiguration={this.widgetConfiguration}>
        <WithGlobalCss styles={css}>
          <WebChat
            chatContext={chatContext}
            conversationId={conversationId}
            noConsole={noConsole ? true : false}
            random={random}
            sgConnectSupport={sgConnectSupport}
          />
        </WithGlobalCss>
      </WidgetConfigurationProvider>
    );
  }


  private emitEvent(this: SgwtSgbotMs, name: string, detail?: object) {
    this.widgetConfiguration.log(
      `[skatejs][event] '${SgwtSgbotMs.is}--${name}' - ${detail
        ? JSON.stringify(detail)
        : '{}'}`
    );
    emit(this, `${SgwtSgbotMs.is}--${name}`, { detail: detail || {} });
  }

  public rendered(): void {
    if (this._firstTime) {
      this._firstTime = false;
      this.emitEvent.call(this, 'ready');
    }
  }
}

initializeWidgetConfiguration(SgwtSgbotMs, {
  disablejQuerySanityCheck: true,
  neverUseShadowDOM: true
});
if (customElements.get(SgwtSgbotMs.is) === undefined) {
  try {
    customElements.define(SgwtSgbotMs.is, SgwtSgbotMs);
  } catch (e) {}
}
