/// <reference path="../../../node_modules/@types/node/index.d.ts" />

import { IWidgetConfigurationContext, widgetConfiguration } from '@sgwt-widget/core';
import { Component, h } from 'preact';
import { DirectLine, DirectLineOptions } from '../shared/DirectLine';
import {
  BUS_ACCESS_TOKEN,
  checkStatus,
  getDirectLineEndpoint,
  getSgConnectEndpoint,
  isDarkTheme,
  parseJson,
} from '../shared/utils';
import {
  Environment,
  IWebChatProps,
  IWebChatState,
} from '../sgwt-sgbot-ms.types';
import { emptyObject } from '../../common/sgwt-widgets-utils';

// ADD IE11 compatibility!!!
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/for-each';
import 'core-js/features/string/starts-with';
// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return;
        }
        this.parentNode.removeChild(this);
      }
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
  Element.prototype.closest = function(s: any) {
    let el: any = this;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
// Event.composedPath
(function(E: any, d: any, w: any) {
  if (!E.composedPath) {
    E.composedPath = function() {
      if (this.path) {
        return this.path;
      }
      let target = this.target;
      this.path = [];
      while (target.parentNode !== null) {
        this.path.push(target);
        target = target.parentNode;
      }
      this.path.push(d, w);
      return this.path;
    };
  }
})(Event.prototype, document, window);

const BotChat = require('botframework-webchat');

const TIMER = 500;

export default class WebChat extends Component<IWebChatProps, IWebChatState> {
  public state: IWebChatState = {
    user: null,
  };
  private _accessTokenSubscription: any;
  private _directLine!: DirectLine;
  private _domain!: string;
  private _isDarkTheme: boolean = false;
  private _mounted: boolean = false;
  private _ref: { root: HTMLDivElement | null; wc: HTMLDivElement | null } = { root: null, wc: null };
  private _sgConnect!: string;
  public context!: IWidgetConfigurationContext;

  constructor(props: IWebChatProps) {
    super(props);
    this._isDarkTheme = isDarkTheme();
  }

  componentDidMount() {
    this._mounted = true;
    const environment = (this.context.widgetConfiguration.environment as Environment) || 'homologation';
    this._domain = getDirectLineEndpoint(environment);
    this._sgConnect = getSgConnectEndpoint(environment, this.props.sgConnectSupport || 'sg-connect-v2');
    this._accessTokenSubscription = this.context.widgetConfiguration.bus.subscribe<string>(BUS_ACCESS_TOKEN, (token?: string): void => {
      if (token) {
        this._handleAccessToken(token);
      }
    });
    if (this._ref.root) {
      setTimeout(() => {
        this._ref.wc = this._ref.root!.querySelector('.wc-message-groups');
        if (this._mounted && this._ref.wc) {
          ['click', 'touchend', 'keypress'].forEach(eventType => { this._ref.wc!.addEventListener(eventType, this._handleRootClicked); });
          this._ref.wc.addEventListener('DOMNodeInserted', this._handleRootUpdated);
        }
      }, TIMER);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this._directLine) {
      this._directLine.end();
    }
    if (this._accessTokenSubscription) {
      this.context.widgetConfiguration.bus.unsubscribe(this._accessTokenSubscription);
    }
    if (this._ref.wc) {
      this._ref.wc.removeEventListener('DOMNodeInserted', this._handleRootUpdated);
      ['click', 'touchend', 'keypress'].forEach(eventType => { this._ref.wc!.removeEventListener(eventType, this._handleRootClicked); });
    }
  }

  render({ noConsole }: IWebChatProps, { user }: IWebChatState) {
    return (
      <div
        class={`${noConsole ? 'wc-no-console' : ''} ${this._isDarkTheme ? 'wc-dark' : ''}`}
        ref={(el: HTMLDivElement) => { this._ref.root = el; }}>
        {!emptyObject(user) && this._directLine && this._domain && (
          <BotChat.Chat
            botConnection={this._directLine}
            user={user}
          />
        )}
      </div>
    );
  }

  private _handleAccessToken = (newToken: string) => {
    const { user } = this.state;
    if (emptyObject(user) && this._domain && this._sgConnect) {
      this.context.widgetConfiguration.fetch(`${this._sgConnect}/oauth2/userinfo`, {
        headers: {
          accept: 'application/json',
          Authorization: newToken,
        },
        method: 'GET',
        mode: 'cors',
      })
        .then(checkStatus)
        .then(parseJson)
        .then((json: any) => {
          if (this._mounted && !emptyObject(json)) {
            const { chatContext, conversationId } = this.props;
            const user = { id: json.sub, name: json.name };
            const options: DirectLineOptions = {
              conversationId,
              domain: this._domain,
              token: newToken,
              watermark: '0',
              webSocket: false
            };
            if (chatContext) {
              options.context = chatContext;
              options.user = user;
              options.fetch = this.context.widgetConfiguration.fetch;
            }
            this._directLine = new DirectLine(options);
            this.setState({ user });
          }
        })
        .catch((): undefined => undefined);
    }
    if (this._directLine) {
      this._directLine.token = newToken;
    }
  };

  private _handleRootClicked = (event: Event | KeyboardEvent) => {
    let rootClicked = false;
    let el: HTMLElement | null = null;
    const path = (event as any).path || ((event as any).composedPath && (event as any).composedPath());
    if (path) {
      for (el of path as HTMLElement[]) {
        if (
          el.nodeType === 1 && el.nodeName.toLowerCase() === 'button' &&
          !el.classList.contains('expandable') &&
          (event.type === 'click' || event.type === 'touchend' ||
          (event.type === 'keypress' && ((event as KeyboardEvent).which === 13 || (event as KeyboardEvent).which === 32))) // Enter and Space
        ) {
          el.classList.add('selected');
          rootClicked = true;
          break;
        }
      }
      if (el && rootClicked) {
        const container = el!.closest('.wc-adaptive-card');
        if (container) {
          const divEl = container.querySelector('div');
          if (divEl) {
            const fs = document.createElement('fieldset');
            fs.disabled = true;
            container.appendChild(fs);
            fs.appendChild(divEl);
          }
        }
      }
    }
  };

  private _handleRootUpdated = () => {
    if (this._mounted && this._ref.wc) {
      this._ref.wc.scrollTop = this._ref.wc.scrollHeight;
    }
  };
}
